<template>
  <div class="Right">
    <!-- 育秧订单右侧未完成订单情况 -->
    <div class="right-nav">
      <ModuleTitle title="未完成订单" />
      <Power></Power>
    </div>
    <!-- 育秧订单右侧已完成订单情况 -->
    <div class="right-tray">
      <ModuleTitle title="已完成订单" />
      <Table></Table>
    </div>
  </div>
</template>

<script>
import ModuleTitle from '../../moduleTitle.vue';
import Power from './righttablelist.vue';
import Table from './rightlist.vue';
export default {
  components: {
    ModuleTitle,
    Power,
    Table
  },
  data() {
    return {

    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.Right {
  position: relative;
  width: companyW(482vw);
  height: 100%;

  .right-nav {
    width: companyW(482vw);
    height: companyH(467vh);
    margin: 0 auto;
    background: rgba(0, 20, 36, 0.4);
    border: 1px solid;
    border-image: linear-gradient(0deg, #99c327, #119edb) 10 10;
    // opacity: 0.74;
  }

  .right-tray {
    position: relative;
    width: companyW(482vw);
    height: companyH(503vh);
    margin-top: companyH(24vh);
    background: rgba(0, 20, 36, 0.4);
    border: companyW(1vw) solid;
    border-image: linear-gradient(0deg, #99C327, #119edb) 10 10;
    // opacity: 0.74;


  }

}
</style>
