<template>
  <!-- 育秧计划柱形图 -->
  <div class="seedling-plan">
    <moduleTitle title="育秧计划"></moduleTitle>

    <div class="plan">
      <Echart :option="option"></Echart>
    </div>





  </div>
</template>

<script>
import moduleTitle from './../moduleTitle.vue';
import Echart from '../../../../components/echarts/index.vue';
import option from '../../config/seeding-orderView/plan.js';
import { getSeedlingPlan } from '../../../../api/seedlingApi/seedling';
export default {
  components: {
    moduleTitle,
    Echart
  },
  data() {
    return {
      option,

    };
  },
  created() {
    this.getseedlingplan()
  },
  methods: {
    getseedlingplan() {
      getSeedlingPlan().then(res => {
        console.log(res);

        this.option.series[0].data = res.data.map(item => {
          return item.count
        })
        this.option.series[1].data = res.data.map(item => {
          // return item.sub
          return item.platePlan
        })
        this.option.series[1].label.formatter = res.data.map(item => {
          return item.platePlan
        })
        this.option.xAxis.data = res.data.map(item => {
          return item.seedingBatch
        })
      }).catch(err => {
        console.log(err);
      })
    }
  }
};
</script>

<style  lang="scss" >
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.seedling-plan {
  position: relative;
  width: companyW(482vw);
  height: companyH(312vh);
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #99C327, #119edb) 10 10;
  // opacity: 0.74;

  .plan {
    position: absolute;
    left: companyW(40vw);
    top: companyH(50vh);
    width: companyW(420vw);
    height: companyH(270vh);
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    padding: 0px;
    margin: 0px;
    border-width: 0px;
  }

}
</style>
