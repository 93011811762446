<template>
  <div class="dyr-service">
    <!-- 头部标题 -->
    <Title class="dyr-service-title" njTitle="top-title" typeOf="digital-page" :title="address+'数字育苗'"></Title>
    <!-- 左侧组件 -->
    <div class="dyr-service-content">
      <div class="dyr-service-left">
        <!-- 左侧服务能力组件 -->
        <Serve></Serve>
        <!-- 左侧订单情况组件 -->
        <Order class="dyr-service-service"></Order>
        <!-- 左侧育秧计划组件 -->
        <plan class="dyr-service-service"></plan>
      </div>
      <!-- 中间组件模块 -->
      <div class="dyr-service-middle">
        <Mddle />
      </div>
      <!-- 右侧组件模块 -->
      <div class="dyr-service-right">
        <Right />
      </div>
    </div>
    <bottomNav class="middle-bottomNav" />
  </div>
</template>
  
<script>
import Title from './components/title.vue';
import Serve from '../seedling/components/seeding-orderView/serve.vue';
import Order from '../seedling/components/seeding-orderView/Order.vue';
import plan from '../seedling/components/seeding-orderView/plan.vue';
import Mddle from './middle/order.vue';
import bottomNav from './components/BottomNav.vue';
import Right from '../seedling/components/seeding-orderView/right/index.vue';
export default {
  mounted(){
        this.address = localStorage.getItem('address')
    },
    data(){
        return{
            address:''
        }
    },
  components: {
    Title,
    Serve,
    Order,
    plan,
    Mddle,
    bottomNav,
    Right
  },
};
</script>
  
<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}


@function companyH($num) {
  @return ($num * 100)/1080;
}

.dyr-service {
  position: relative;
  width: companyW(1920vw);
  height: companyH(1080vh);
  box-sizing: border-box;
  background-image: url("../../assets/digital-page/orderbgc.png");
  // background-color: rgba(8, 27, 25);
  background-size: cover;
  background-repeat: no-repeat;

  .dyr-service-title {
    padding-top: companyH(8vh);
  width: companyW(1820vw);
  height: companyH(70vh);
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  }

  .dyr-service-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .dyr-service-left {
      width: companyW(482vw);
      height: companyH(978vh);
      margin-left: companyW(20vw);

      .dyr-service-service {
        margin-top: companyH(21vh);
      }
    }

    .dyr-service-right {
      margin-right: companyW(20vw);
      height: companyH(978vh);

    }
  }

  .middle-bottomNav {
    position: absolute;
    bottom: 0;
    left: companyW(562vw);
    background-image: url(../../assets/digital-page/b.png);
    background-size: center;
    background-repeat: no-repeat;
  }
}
</style>
  