<template>
  <!-- 服务能力饼图样式 -->
  <div class="dry-power">
    <moduleTitle title="服务能力"></moduleTitle>
    <!--左边饼图  -->
    <div class="left">
      <Echart :option="dbsnOption"></Echart>
    </div>
    <!-- 中间饼图 -->
    <div class="center">
      <Echart :option="valOption"></Echart>
    </div>
    <!-- 右侧饼图 -->
    <div class="right">
      <Echart :option="dataOption"></Echart>
    </div>

  </div>
</template>

<script>
import moduleTitle from './../moduleTitle.vue';
import Echart from "@/components/echarts/index.vue";
import * as dbsnOption from '../../config/seeding-orderView/serveCircle.js'
import * as valOption from '../../config/seeding-orderView/serveCircle.js'
import * as dataOption from '../../config/seeding-orderView/serveCircle.js'
import { getSeedlingOrderNum } from '../../../../api/seedlingApi/seedling';
export default {
  // props: {
  //   propOptions: {
  //     type: Object,
  //     required: true,
  //     default: () => { },
  //   },
  // },
  components: {
    moduleTitle,
    Echart,
  },
  data() {
    return {
      // option: this.propOptions,
      ...dbsnOption,
      ...valOption,
      ...dataOption,
      ordernum: {},
    };
  },
  created() {
    this.getseedlingordernum()

  },
  methods: {
    getseedlingordernum() {
      getSeedlingOrderNum().then(res => {
        console.log(res);
        this.ordernum = res.data
        // 左边饼图
        this.dbsnOption.title[0].text = `{a|${(this.ordernum.amountOrderNum / this.ordernum.amountOrderNum) * 100}}{b|%}`
        this.dbsnOption.title[1].text = `{a|${this.ordernum.amountOrderNum} 盘}\n{b|订单总量}`
        this.dbsnOption.series[0].axisLine = {
          show: true,
          lineStyle: {
            color: [
              [0, "#ffa235"],
              [99.99 / 100, "#0D4B3F"],
              [1, "#27EAC1"],
            ],
            width: 20,
          },
        };
        // 中间饼图
        this.valOption.title[0].text = `{a|${Math.ceil((this.ordernum.finishOrderNum / this.ordernum.amountOrderNum) * 100)}}{b|%}`
        this.valOption.title[1].text = `{a|${this.ordernum.finishOrderNum} 盘}\n{b|已完成订单}`
        this.valOption.series[0].axisLine = {
          show: true,
          lineStyle: {
            color: [
              [0, "#021219"],
              [this.ordernum.finishOrderNum / this.ordernum.amountOrderNum, "#07403F"],
              [1, "#28E8FF"],
            ],
            width: 20,
          },
        };
        // 右侧饼图
        this.dataOption.title[0].text = `{a|${Math.ceil((this.ordernum.unfinishOrderNum / this.ordernum.amountOrderNum) * 100)}}{b|%}`
        this.dataOption.title[1].text = `{a|${this.ordernum.unfinishOrderNum} 盘}\n{b|未完成订单}`
        this.dataOption.series[0].axisLine = {
          show: true,
          lineStyle: {
            color: [
              [0, "#021219"],
              [this.ordernum.unfinishOrderNum / this.ordernum.amountOrderNum, "#323B22"],
              [1, "#E5C452"],
            ],
            width: 20,
          },
        };




      }).catch(err => {
        console.log(err);
      })
    }
  }
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-power {
  position: relative;
  width: companyW(482vw);
  height: companyH(292vh);
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #99C327, #119edb) 10 10;
  // opacity: 0.74;
  display: flex;

  // 左边饼图
  .left {
    position: relative;
    width: companyW(160vw);
    height: companyH(292vh);
    // background: rgba(0, 20, 36, 0.4);
    top: companyH(-20vh);
  }

  // 中间饼图
  .center {
    position: relative;
    width: companyW(160vw);
    height: companyH(292vh);
    // background: rgba(0, 20, 36, 0.4);
    top: companyH(-20vh);
  }

  // 右边饼图
  .right {
    position: relative;
    width: companyW(160vw);
    height: companyH(292vh);
    // background: rgba(0, 20, 36, 0.4);
    top: companyH(-20vh);
  }
}
</style>
