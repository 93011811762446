<template>
    <div>
        <!-- 未完成订单查看详情页 -->
        <el-dialog :visible.sync="seeVisible" :show-close="false" @close="closemask" :modal="false">
            <dv-border-box-1 :color="['#99C327', '#D6E2B5']">
                <div class="toolBox">
                    <div class="toptitle">订单详情</div>
                    <div class="del" @click='seeVisible = false'>X</div>
                    <div class="list">
                        <div class="xinxi">
                            <span>订单对象</span>
                            <p>{{ tableobj.farmerName }}</p>
                        </div>
                        <div class="xinxi">
                            <span>联系方式</span>
                            <p>{{ tableobj.farmerTel }}</p>
                        </div>
                        <div class="xinxi">
                            <span>育秧品种</span>
                            <p>{{ tableobj.seedingVariety }}</p>
                        </div>
                        <div class="xinxi">
                            <span>育秧类型</span>
                            <p>{{ tableobj.seedingType }}</p>
                        </div>
                        <div class="xinxi">
                            <span>育秧数量</span>
                            <p>{{ tableobj.seedingNum }} 盘</p>
                        </div>
                        <div class="xinxi">
                            <span>交苗时间</span>
                            <p>{{ tableobj.appointmentStart }}~{{ tableobj.appointmentEnd }}</p>
                        </div>
                        <div class="xinxi">
                            <span>交苗方式</span>
                            <p>{{ tableobj.deliveryType }}</p>
                        </div>
                        <div class="xinxi">
                            <span>配送地址 </span>
                            <div>(需配送显示)</div>
                            <p>{{ tableobj.fieldAddress }}</p>
                        </div>
                    </div>
                    <!-- <div class="submit">
                        <button class="submitBox" @click="Submit">编辑</button>
                    </div> -->
                </div>
            </dv-border-box-1>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        seeVisible: {
            type: Boolean,
            default: false
        },
        tableobj: {
            type: Object,
            default: () => {
                return {}
            }
        }

    },
    components: {},
    data() {
        return {
        }
    },
    mounted() {
        console.log(this.tableobj);
    },
    methods: {
        closemask() {
            this.$emit('closemask', false);
        },
        Submit() {
            console.log(1);
        },

    }
};
</script>

<style scoped lang="scss">
@function companyW($num) {
    @return ($num * 100)/1920;
}

@function companyH($num) {
    @return ($num * 100)/1080;
}

* {
    font-family: Source Han Sans CN;
    font-size: companyW(18vw);
    box-sizing: border-box;
    color: #fff;
}

::v-deep .el-dialog {
    width: companyW(930vw);
    height: companyH(620vh);
    background-color: #000;
    border-radius: companyH(40vh);

    margin: 0 auto;

    .el-input__icon {
        line-height: companyH(30vh);
    }
}

.dv-border-box-1 {
    position: relative;
    width: companyW(930vw);
    height: companyH(620vh);
}

::v-deep .el-tabs--left .el-tabs__item.is-left {
    text-align: left;
}

::v-deep .el-dialog__body {
    padding: 0;

}

::v-deep .el-dialog__header {
    padding: 0;
}



.toolBox {
    height: companyH(580vh);

    padding-left: companyW(80vw);
    padding-right: companyW(80vw);
    position: relative;

    .toptitle {
        width: 100%;
        text-align: center;
        font-size: companyW(30vw);
        padding-top: companyH(30vh);
        margin-bottom: companyH(50vh);
    }

    .del {
        position: absolute;
        top: companyH(30vh);
        right: companyH(30vh);
        width: companyW(40vw);
        height: companyH(40vh);
        text-align: center;
        line-height: companyH(35vh);
        border-radius: 50%;
        font-size: companyH(30vh);
        color: #000;
        font-weight: 500;
        background-color: #fff;

    }

    .list {
        position: absolute;
        left: companyW(145vw);
        top: companyH(102vh);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .xinxi {
            width: companyW(280vw);
            height: companyH(120vh);
            text-align: center;
            position: relative;

            span {
                display: block;
                width: companyW(150vw);
                height: companyH(30vh);
                font-size: companyW(24vw);
                background: url('../../../../../assets/digital-page/juxing32.png') no-repeat left;
            }

            p {
                // width: companyW(260vw);
                height: companyH(30vh);
                text-align: left;
                padding-left: companyW(20vw);
                color: lightgreen;
                margin-top: companyH(10vh);
                font-size: companyW(22vw);

            }

            div {
                position: absolute;
                right: companyW(50vw);
                top: companyH(8vh);
                font-size: companyH(20vh);
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #6D8A94;
            }

        }
    }

    .submit {
        position: absolute;
        left: companyW(360vw);
        bottom: companyH(0vh);
        // margin-top: companyH(75vh);
        text-align: center;

        .submitBox {
            width: companyW(200vw);
            height: companyH(40vh);
            font-size: companyW(16vw);
            border: 1px solid #99C327;
            color: #99C327;
            background-color: transparent;
        }
    }
}
</style>
