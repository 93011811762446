<template>
  <!-- 订单情况图形样式 -->
  <div class="seedling-Order">
    <moduleTitle title="订单情况"></moduleTitle>
    <div class="images">
      <div class="left">
        <span class="span1">{{ seedlingnum.selfOrder }}</span>
        <img src="../../../../assets/digital-page/yuan.png" alt="" />
      </div>
      <div class="center">
        <img src="../../../../assets/digital-page/gun.png" alt="" />
      </div>
      <div class="right">
        <span class="span2">{{ seedlingnum.outOrder }}</span>
        <img src="../../../../assets/digital-page/yuan.png" alt="" />
      </div>
    </div>
    <div class="text">
      <span>自我育秧(盘)</span>
      <span>对外育秧(盘)</span>
    </div>
  </div>
</template>

<script>
import moduleTitle from './../moduleTitle.vue';
import { getSeedlingOrderNum } from '../../../../api/seedlingApi/seedling';
// import Echart from '../../../../components/echarts/index.vue';
export default {
  components: {
    moduleTitle,
    // Echart
  },
  data() {
    return {
      // option
      seedlingnum: {},
    };
  },
  created() {
    this.getseedlingordernum();
  },
  mounted() {

  },
  methods: {
    getseedlingordernum() {
      getSeedlingOrderNum().then(res => {
        console.log(res);
        this.seedlingnum = res.data;
      }).catch(err => {
        console.log(err);
      });
    }
  }
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.seedling-Order {
  position: relative;

  width: companyW(482vw);
  height: companyH(342vh);
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #99c327, #119edb) 10 10;
  // opacity: 0.74;

  .images {
    font-size: companyH(35vh);
    color: #a7e500;
    position: absolute;
    top: companyH(100vh);
    width: companyW(482vw);
    height: companyH(200vh);
    display: flex;
    justify-content: space-around;
    align-items: center;

    .span1 {
      position: absolute;

      top: companyH(60vh);
      width: companyW(160vw);
      text-align: center;
    }

    .span2 {
      position: absolute;

      top: companyH(60vh);
      width: companyW(160vw);
      text-align: center;
    }
  }

  .text {
    position: absolute;
    width: companyW(482vw);
    height: companyH(20vh);
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: companyH(280vh);

    span {
      font-size: companyH(22vh);
      color: #e6e6e6;
      font-weight: 500;
      margin-top: companyH(10vh);
      font-family: Source Han Sans CN;
      width: companyW(215vw);
      height: companyH(20vh);
      text-align: center;
      line-height: companyH(20vh);
    }
  }
}
</style>
