 let value = 92;
export const dbsnOption = {
  backgroundColor:'rgba(60,84,14,0)',
  title: [
    {
      text: `{a|${value}}{b|%}`,
      x: "center",
      y: "center",
      textStyle: {
        rich: {
          a: {
            fontSize: 20                      ,
            color: "#27EAC1",
            padding:[0,0,4,0]
          },
          b: {
            fontSize: 14,
            color: "#27EAC1",
            padding: [0, 0, 8, 0],
          },
        },
      },
    },
    
    {
      text: `{a|920 盘}\n{b|订单总量}`,
      bottom: "0%",
      x: "center",
      textStyle: {

         rich: {
          a: {
            fontSize: 24 ,
            color: "#27EAC1",
            padding:[10,0,0,0]
          },
          b: {
            fontSize: 22,
            color: "#fff",
             padding:[0,0,0,0]
          },
        },
      },
    },
  ],      
  series: [
    {
      type: "gauge",
      radius:"60%",
      clockwise: false,
      startAngle: "90",
      endAngle: "-269.9999",
      splitNumber: 25,
      detail: {
        offsetCenter: [0, -20],
        formatter: " ",
      },
      pointer: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: [
            [0, "#ffa235"],
            [value / 100,"#0D4B3F"],
            [1,  "#27EAC1"],
          ],
          width: 20,
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
        length: 20,
        lineStyle: {
          color: "rgba(0,0,0,0.5)",
          width: 2,
        },
      },
      axisLabel: {
        show: false,
      },
    },
    {
      type: "pie",
      name: "内层细圆环",
    //   radius: ["52%", "54%"],
       radius: ["66%", "68%"],
      hoverAnimation: false,
      clockWise: false,
      itemStyle: {
        normal: {
          color: "#454E51",
        },
      },
      label: {
        show: false,
      },
      data: [100],
    },
    {
      type: "pie",
      name: "内层环",
      radius: [0, "20%"],
      hoverAnimation: false,
      clockWise: false,
      itemStyle: {
        normal: {
          // color: "red",
          color: "rgba(195,230,100,0)",
        },
      },
      label: {
        show: false,
      },
      data: [100],
    },
  ],
};
 let val = 2.9;
export const valOption = {
  backgroundColor:'rgba(60,84,14,0)',
  title: [
    {
      text: `{a|${val}}{b|%}`,
      x: "center",
      y: "center",
      textStyle: {
        rich: {
          a: {
            fontSize: 20                      ,
            color: "#28E8FF",
            padding:[0,0,4,0],
          },
          b: {
            fontSize: 14,
            color: "#28E8FF",
            padding: [0, 0, 8, 0],
          },
        },
      },
    },
    
     {
      text: `{a|30 盘}\n{b|已完成订单}`,
      bottom: "0%",
      x: "center",
      textStyle: {

         rich: {
          a: {
            fontSize: 24 ,
            color: "#28E8FF",
            padding:[10,0,0,0]
          },
          b: {
            fontSize: 22,
            color: "#fff",
             padding:[0,0,0,0]
          },
        },
      },
    },
  ],      
  series: [
    {
      type: "gauge",
      radius:"60%",
      clockwise: false,
      startAngle: "90",
      endAngle: "-269.9999",
      splitNumber: 25,
      detail: {
        offsetCenter: [0, -20],
        formatter: " ",
      },
      pointer: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: [
            [0, "#021219"],
            [val/ 100,"#07403F"],
            [1,  "#28E8FF"],
          ],
          width: 20,
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
        length: 20,
        lineStyle: {
          color: "rgba(0,0,0,0.5)",
          width: 2,
        },
      },
      axisLabel: {
        show: false,
      },
    },
    {
      type: "pie",
      name: "内层细圆环",
      radius: ["66%", "68%"],
    //   radius: ["46%", "48%"],
      hoverAnimation: false,
      clockWise: false,
      itemStyle: {
        normal: {
          color: "#454E51",
        },
      },
      label: {
        show: false,
      },
      data: [100],
    },
    {
      type: "pie",
      name: "内层环",
      radius: [0, "20%"],
      hoverAnimation: false,
      clockWise: false,
      itemStyle: {
        normal: {
          // color: "red",
          color: "rgba(195,230,100,0)",
        },
      },
      label: {
        show: false,
      },
      data: [100],
    },
  ],
};
 let data = 12;
export const dataOption = {
  backgroundColor:'rgba(60,84,14,0)',
  title: [
    {
      text: `{a|${data}}{b|%}`,
      x: "center",
      y: "center",
      textStyle: {
        rich: {
          a: {
            fontSize: 20                      ,
            color: "#E5C452",
            padding:[0,0,4,0]
          },
          b: {
            fontSize: 14,
            color: "#E5C452",
            padding: [0, 0, 8, 0],
          },
        },
      },
    },
    
        {
      text: `{a|120 盘}\n{b|未完成订单}`,
      bottom: "0%",
      x: "center",
      textStyle: {

         rich: {
          a: {
            fontSize: 24 ,
            color: "#E5C452",
            padding:[10,0,0,0]
          },
          b: {
            fontSize: 22,
            color: "#fff",
             padding:[0,0,0,0]
          },
        },
      },
    },
  ],      
  series: [
    {
      type: "gauge",
      radius:"60%",
      clockwise: false,
      startAngle: "90",
      endAngle: "-269.9999",
      splitNumber: 25,
      detail: {
        offsetCenter: [0, -20],
        formatter: " ",
      },
      pointer: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: [
            [0, "#021219"],
            [data/ 100,"#323B22"],
            [1,  "#E5C452"],
          ],
          width: 20,
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
        length: 20,
        lineStyle: {
          color: "rgba(0,0,0,0.5)",
          width: 2,
        },
      },
      axisLabel: {
        show: false,
      },
    },
    {
      type: "pie",
      name: "内层细圆环",
      radius: ["66%", "68%"],
    //   radius: ["46%", "48%"],
      hoverAnimation: false,
      clockWise: false,
      itemStyle: {
        normal: {
          color: "#454E51",
        },
      },
      label: {
        show: false,
      },
      data: [100],
    },
    {
      type: "pie",
      name: "内层环",
      radius: [0, "20%"],
      hoverAnimation: false,
      clockWise: false,
      itemStyle: {
        normal: {
          // color: "red",
          color: "rgba(195,230,100,0)",
        },
      },
      label: {
        show: false,
      },
      data: [100],
    },
  ],
};


