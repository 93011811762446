<template>
  <div class="earch">
    <div class="earchimg">
      <div class="text">

        <p>当年累计育秧量</p>
        <span class="span1">{{ seedlingnum.thisYearOrderNum }}</span><span>盘</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getSeedlingOrderNum } from '../../../api/seedlingApi/seedling';
export default {
  data() {
    return {
      seedlingnum: {},
    };
  },
  mounted() {
    this.getseedlingordernum()
  },
  methods: {
    getseedlingordernum() {
      getSeedlingOrderNum().then(res => {
        console.log(res);
        this.seedlingnum = res.data
      }).catch(err => {
        console.log(err);
      })
    }
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.earch {
  position: relative;
  width: companyW(920vw);
  height: companyH(870vh);
  top: companyH(70vh);
  left: companyW(100vw);
  overflow: hidden;

  .earchimg {
    width: companyW(750vw);
    height: 100%;
    background: url("../../../assets/digital-page/diqiu.png") no-repeat;
    background-size: contain;

    .text {
      position: relative;
      // left: companyW(260vw);
      top: companyH(230vh);

      text-align: center;

      p {
        width: companyW(750vw);
        height: companyH(45vh);
        line-height: companyH(45vh);
        font-size: companyH(42vh);
        font-weight: 500;
        color: #fff;
        font-family: Source Han Sans SC;
        margin-bottom: companyH(20vh);
        text-align: center;

      }

      span {
        width: companyW(195vw);
        height: companyH(53vh);
        line-height: companyH(53vh);
        font-size: companyH(35vh);
        font-family: DIN;
        font-weight: 400;
        color: #2aead0;
        background: linear-gradient(0deg, #2cf4d1 0%, #22d0e5 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .span1 {
        width: companyW(195vw);
        height: companyH(70vh);
        line-height: companyH(70vh);
        font-size: companyH(70vh);
        font-family: DIN;
        font-weight: 400;
        color: #2aead0;
        background: linear-gradient(0deg, #2cf4d1 0%, #22d0e5 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
</style>
