<template>
  <!-- 育秧订单右侧已完成订单列表 -->
  <div class="table">
    <!-- 选项列表 -->
    <el-select v-model="currentTime" @change="getchange">
      <el-option v-for="item in TimeOptions" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
    <!-- 订单列表 -->
    <ul class="tb">
      <li v-for="item in tableList">
        <img src="../../../../../assets/digital-page/yang.png" alt="" v-if="item.seedingType == '秧苗'" />
        <img src="../../../../../assets/digital-page/ya.png" alt="" v-else="item.seedingType == '芽苗'" />
        <span class="duixiang">订单对象：{{ item.farmerName }}</span>
        <span>订单数量：{{ item.seedingNum }}盘</span><span class="spanimg" @click="Seeorder(item)"></span>
      </li>
    </ul>
    <seeorder :seeVisible="seeVisible" @closemask="closemask" :tableobj="tableobj"></seeorder>
  </div>
</template>
 
<script>
import seeorder from './seeorder2.vue';
import { getSeedlingOrder } from '../../../../../api/seedlingApi/seedling';
export default {
  components: {

    seeorder,
  },
  data() {
    return {
      num: 0,
      seeVisible: false,
      tableobj: {},
      tableList: [
        // {
        //   name: "屋大维",
        //   num: "18",
        //   img: require("../../../../../assets/digital-page/ya.png"),
        // },
        // {
        //   name: "太史慈",
        //   num: "12",
        //   img: require("../../../../../assets/digital-page/yang.png"),
        // },
        // {
        //   name: "阿凡达",
        //   num: "10086",
        //   img: require("../../../../../assets/digital-page/yang.png"),
        // },
        // {
        //   name: "孙悟空",
        //   num: "1200",
        //   img: require("../../../../../assets/digital-page/ya.png"),
        // },
        // {


      ],
      currentTime: "全部",
      TimeOptions: [
        {
          value: 0,
          label: "全部"
        },
        {
          value: 1,
          label: "芽苗",
        },
        {
          value: 2,
          label: "秧苗",
        },
      ],
    };
  },
  mounted() {
    this.getseedlingorder(this.num)
  },
  methods: {
    // 订单详情页回调函数
    closemask(item) {
      console.log(item, 11111);
      this.seeVisible = item

    },
    // 订单详情页订单
    Seeorder(data) {
      this.seeVisible = true
      console.log(this.seeVisible);
      console.log(data, 22222);
      this.tableobj = data
    },
    // 选择框事件
    getchange(e) {
      console.log(e)
      this.getseedlingorder(e);
    },
    // 调用完成订单
    getseedlingorder(num) {
      let flag = true
      let data = num
      getSeedlingOrder(flag, data).then(res => {
        this.tableList = res.data.records
        console.log(res);
      }).catch(err => {
        console.log(err);
      })
    }
  },
};
</script>
 
<style  lang="scss" scoped >
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

::v-deep.el-select {
  position: absolute;
  left: companyW(30vw);
  top: companyH(25vh);
  background: #1e2801;
  z-index: 3;

  .el-input__inner {
    width: companyW(100vw);
    height: companyH(35vh);
    background: #1e2801;
    border-radius: 0;
    border: #8fc201 companyW(1vw) solid;
    font-size: companyH(18vh);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #e1eaea;
    line-height: companyH(35vh);
  }

  .el-input__icon {
    line-height: companyH(30vh);
  }
}

.table {
  position: relative;
  top: companyH(30vh);
  width: companyW(482vw);
  height: companyH(437vh);

  // 选择框


  // 订单列表
  .tb {
    position: absolute;
    top: companyH(62vh);
    width: companyW(482vw);
    height: companyH(400vh);
    overflow-y: scroll;
    overflow-x: hidden;
    list-style: none;
    padding: 0 companyW(30vw);
    box-sizing: border-box;

    ::-webkit-scrollbar-thumb {
      background-color: #99c327;
    }

    li {
      width: companyW(420vw);
      height: companyH(60vh);
      font-size: companyH(18vh);
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #fff9f8;
      text-align: center;
      line-height: companyH(60vh);
      border-bottom: companyW(2vw) solid rgba(153, 195, 39, 0.3);
      display: flex;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;

      span {
        width: companyW(180vw);
        text-align: left;
        color: #fff;
      }

      img {
        width: companyW(20vw);
        height: companyH(20vh);
        z-index: 1;
        margin-right: companyH(5vh);
      }

      .spanimg {
        width: companyW(80vw);
        height: companyH(40vh);

        background: url(../../../../../assets/digital-page/dingdan.png) no-repeat center center;
        background-size: cover;
      }
    }
  }

  .tb::-webkit-scrollbar {
    width: companyW(5vw);
  }

  .tb::-webkit-scrollbar-track {
    box-shadow: inset 0 0 companyW(5vw) rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .tb::-webkit-scrollbar-thumb {
    background-color: #99c327;
    box-shadow: inset 0 0 companyW(5vw) rgba(0, 0, 0, 0.2);
    border-radius: companyW(5vw);
  }
}
.duixiang{
  white-space: nowrap; //取消换行
    overflow: hidden; //超出隐藏
    text-overflow: ellipsis; //文字超出三点
}
</style>
 