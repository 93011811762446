import * as echarts from "echarts";

export default {
  title: {
    top: 20,
    left: 20,
    show: false,
    text: "总数",
    textStyle: {
      color: "#fff",
      fontSize: "18",
    },
  },
  legend: {
    show: true,
    x: "right",
    y: "15",
    itemWidth: 18,
    textStyle: {
      color: "#ECF0FF",
      fontSize:16
    },
  },
  xAxis: {
    type: "category",
    data: ["第一批", "第二批", "第三批", "第四批", "第五批", "第六批"],

    nameTextStyle: {
      align: "left",
     
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#3E5063",
      },
    },
    axisLabel: {
      interval: 0,
      color: "#ECF0FF",
      fontSize:18
    },
  },
  yAxis: {
    type: "value",
    axisLine: {
      show: true,
      lineStyle: {
        color: "#3E5063",
      },
    },
    splitLine: {
    
      lineStyle: {
        color: "rgba(159, 159, 159, 0.22)",
      },
    },
    axisLabel: {
      show: true,
      interval: 0,
      color: "#ECF0FF",
    },
  },
    dataZoom: [

    {
      type: "inside",
      show: true,
        xAxisIndex: [0],
      height: 15,
      start: 1,
      end: 100,
    },
  ],
  series: [
        {
          name:'实际育秧量',
      data: ["4500", "500", "500", "804", "804", "909"],
      barWidth: 20,
      type: "bar",
      stack: '总量',
      barGap: "10%",
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    { offset: 0, color: `#27E6AB` },
          { offset: 1, color: `#030F30` },
        ]),
      } ,
      label: {
        show: true, //是否展示
        position: "insideTop",
              formatter: function (p) {
              return p.value > 0 ? p.value : "";
            },
        textStyle: {
          fontWeight: "bolder",
          fontSize: "18",
          fontFamily: "微软雅黑",
          color: "#fff",
        },
      },
    },
    {
        name:'计划育秧量',
      data: ["60", "96", "86", "84", "84", "99"],
      barWidth: 20,
      type: "bar",
      barGap: "10%",
      stack: '总量',
      itemStyle: {
 
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: `#B7B8B6` },
          { offset: 1, color: `#020B24 ` },
        ]),
      },
      label: {
        show: true, //是否展示
        position: "top",
              formatter: function (p) {
              return p.value > 0 ? p.value : "";
            },
        textStyle: {
          fontWeight: "bolder",
          fontSize: "18",
          fontFamily: "微软雅黑",
          color: "#fff",
        },
      },
    },

  ],
};