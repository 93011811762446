<template>
    <div>
        <!-- 新增委托订单 -->
        <el-dialog :visible.sync="addVisible" :show-close="false" @close="closeMask" :modal="false">
            <dv-border-box-1 :color="['#99C327', '#D6E2B5']">
                <div class="toolBox">
                    <div class="toptitle">新增委托</div>
                    <div class="del" @click='clickdel'>X</div>
                    <div class="formItem">
                        <div class="formBox">
                            <div class="formTxtBox">订单对象</div>
                            <div class="selectBox">
                                <el-select v-model="value" filterable placeholder="请选择" :popper-append-to-body="false"
                                    @change="getID(value)">
                                    <el-option v-for="item in options" :key="item.id"
                                        :label="`${item.name} / ${item.telphone}`" :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <div style="text-align: center; line-height: 70px; color: #005b5b">
                                |
                            </div>
                            <div class="buttonBox">
                                <button>新增农户</button>
                            </div>
                        </div>
                        <div class="formBox">
                            <div class="formTxtBox">育秧品种</div>
                            <div class="checkBox">

                                <el-radio v-model="radio2" :label="item.varietyName" v-for="item, index in tablelist"
                                    :value="item.varietyName"
                                    @input="(value, a, b) => { getprise(value, item.sproutMoney, item.seedingMoney) }">{{
                                        item.varietyName
                                    }}</el-radio>
                            </div>
                        </div>
                        <div class="formBox">
                            <div class="formTxtBox">育秧类型</div>
                            <div class="checkBox">
                                <el-radio v-model="radio" label="芽苗" @input="getprise">芽苗</el-radio>
                                <el-radio v-model="radio" label="秧苗" @input="getprise">秧苗</el-radio>
                            </div>

                        </div>

                        <div class="formBox">
                            <div class="formTxtBox">育秧数量</div>
                            <div class="iput">
                                <input type="text" :value="inputval" class="ipt" @input="getnum($event)"
                                    placeholder="0"><span>盘</span>
                            </div>
                            <div class="formTxtBox">育秧单价</div>
                            <div class="iput">
                                <input type="text" :value="inputval3" class="ipt" :readonly="true"><span>元</span>
                            </div>
                            <div class="formTxtBox">育秧总价</div>
                            <div class="iput">
                                <input type="text" :value="inputval2" class="ipt" :readonly="true"><span>元</span>
                            </div>
                        </div>

                        <div class="formBox">
                            <div class="formTxtBox">交苗时间</div>
                            <div class="dateBox">

                                <el-date-picker :clearable="true" value-format="yyyy-MM-dd HH:mm:ss" v-model="time"
                                    type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                    style="width: 93%">
                                </el-date-picker>
                            </div>
                        </div>
                        <div class="formBox">
                            <div class="formTxtBox">交苗方式</div>

                            <div class="dateBox">
                                <el-radio v-model="radio1" label="自取">中心自取</el-radio>
                                <el-radio v-model="radio1" label="配送">需要配送</el-radio>
                            </div>
                            <div class="peisong" v-if="radio1 == '配送'">
                                <input type="text" value="配送地址" class="PS">
                                <el-select v-model="fieldValue" filterable placeholder="请选择地址"
                                    :popper-append-to-body="false">
                                    <el-option v-for="item in options2" :key="item.id" :label="item.fieldName"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="submit">
                        <button class="submitBox" @click="Submit">确认委托</button>
                    </div>
                </div>
            </dv-border-box-1>
        </el-dialog>
    </div>
</template>

<script>
import { saveSeedlingOrder, getFarmer, getVarirty, getFarmerField, getFarmerFieldID, getFarmerID } from '../../../../../api/seedlingApi/seedling';
export default {
    props: {
        addVisible: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    data() {
        return {
            ya: '',
            yang: '',
            // 当前时间日期
            time: '',
            // 农户信息
            farmer: '',
            //育秧盘数
            inputval: '',
            // 育秧价格
            inputval2: 0,
            // 育秧单价
            inputval3: 0,
            // 交苗方式单选
            radio: '芽苗',
            // 配送地址单选
            radio1: '配送',
            // 品种单选
            radio2: '',
            // 配送地址
            fieldValue: '',
            // 农户列表
            options: [
                {
                    value: '选项1',
                    label: '屋大为/18757421574'
                },
                {
                    value: '选项2',
                    label: '屋大为/18757421574'
                },
                {
                    value: '选项3',
                    label: '屋大为/18757421574'
                },
                {
                    value: '选项4',
                    label: '屋大为/18757421574'
                },
                {
                    value: '选项5',
                    label: '屋大为/18757421574'
                }
            ],
            // 田块列表
            options2: [
                {
                    value: '选项1',
                    label: '田块一'
                },
                {
                    value: '选项2',
                    label: '田块二'
                },
                {
                    value: '选项3',
                    label: '田块三'
                },
                {
                    value: '选项4',
                    label: '田块四'
                },

            ],
            // 育秧品种列表
            tablelist: [

            ],
            // 订单对象
            value: '',
        };
    },
    mounted() {
        this.getfarmer(this.farmer)
        this.getvarirty()

    },
    methods: {
        // 弹框回调函数
        closeMask() {

            this.value = '',
                this.radio = '芽苗',
                this.time = '',
                this.inputval = '',
                this.radio2 = '',
                this.fieldValue = '',
                this.inputval2 = 0
            this.$emit('closeMask', false);
        },
        // 点击关闭按钮
        clickdel() {
            this.value = '',
                this.radio = '芽苗',
                this.time = '',
                this.inputval = '',
                this.radio2 = '',
                this.fieldValue = '',
                this.inputval2 = 0
            this.addVisible = false
        },
        // 提交事件
        Submit() {
            if (this.radio1 == '自取') {
                if (this.value, this.radio, this.radio1, this.radio2, this.inputval, this.time) {
                    let farmerId = null

                    getFarmerID(this.value).then(res => {
                        console.log(res);
                        farmerId = res.data

                    }).catch(err => {
                        console.log(err);
                    })
                    setTimeout(() => {
                        const data = {
                            seedingOrder: {
                                farmerId: this.value,//农户id
                                farmerName: farmerId.name, //农户名称
                                farmerTel: farmerId.telphone, //农户电话
                                seedingType: this.radio, //育秧类型（芽苗/秧苗）
                                seedingVariety: this.radio2, //育秧品种
                                seedingPrice: this.inputval3, //育秧单价
                                deliveryType: this.radio1, //交苗方式（自取/配送）(如果选配送，需带上田块id，名称)
                                appointmentStart: this.time[0], //预约交苗开始时间
                                appointmentEnd: this.time[1], //预约交苗结束时间
                                remark: "备注", //备注
                                seedingAmount: this.inputval2, //总价
                                isSelf: false, //是否自我订单（1为自我，0为对外）
                            },
                            list: [
                                {
                                    fieldId: '', //田块id
                                    fieldName: '', //田块名称
                                    fieldAddress: '', //田块地址
                                    seedingNum: this.inputval, //育秧盘数
                                }
                            ]
                        }
                        console.log(data);
                        this.saveseedlingorder(data)
                        this.value = '',
                            this.radio = '芽苗',
                            this.time = '',
                            this.inputval = '',
                            this.radio2 = '',
                            this.inputval2 = 0
                        this.addVisible = false
                    }, 500)




                } else {
                    console.log('不能为空');
                }
            } else {
                if (this.value, this.radio, this.radio1, this.radio2, this.inputval, this.time, this.fieldValue != '') {
                    let farmerId = null

                    getFarmerFieldID(this.fieldValue).then(res => {
                        console.log(res);
                        farmerId = res.data

                    }).catch(err => {
                        console.log(err);
                    })
                    setTimeout(() => {
                        const data = {
                            seedingOrder: {
                                farmerId: this.value,//农户id
                                farmerName: farmerId.lessee, //农户名称
                                farmerTel: farmerId.telphone, //农户电话
                                seedingType: this.radio, //育秧类型（芽苗/秧苗）
                                seedingVariety: this.radio2, //育秧品种
                                seedingPrice: this.inputval3, //育秧单价
                                deliveryType: this.radio1, //交苗方式（自取/配送）(如果选配送，需带上田块id，名称)
                                appointmentStart: this.time[0], //预约交苗开始时间
                                appointmentEnd: this.time[1], //预约交苗结束时间
                                remark: "备注", //备注
                                seedingAmount: this.inputval2, //总价
                                isSelf: false, //是否自我订单（1为自我，0为对外）
                            },
                            list: [
                                {
                                    fieldId: farmerId.id, //田块id
                                    fieldName: farmerId.fieldName, //田块名称
                                    fieldAddress: farmerId.address, //田块地址
                                    seedingNum: this.inputval, //育秧盘数
                                }
                            ]
                        }
                        console.log(data);
                        this.saveseedlingorder(data)
                        this.value = '',
                            this.radio = '芽苗',
                            this.time = '',
                            this.inputval = '',
                            this.radio2 = '',
                            this.fieldValue = ''
                        this.inputval2 = 0
                        this.addVisible = false
                    }, 500)




                } else {
                    console.log('不能为空');
                }
            }


        },
        // 获取盘数
        getnum(e) {
            this.inputval = e.target.value
            console.log(this.inputval);
            this.getprise()
        },
        // 获取价格
        getprise(value, ya, yang) {
            if (ya || yang) {
                this.ya = ya
                this.yang = yang
            }

            // console.log(this.ya, 'this.ya');
            // console.log(this.yang, 'this.yang');
            if (this.radio == '芽苗') {
                this.inputval3 = this.ya
                this.inputval2 = this.ya * this.inputval
                console.log(this.inputval2);
            }
            if (this.radio == '秧苗') {
                this.inputval3 = this.yang
                this.inputval2 = this.yang * this.inputval
                console.log(this.inputval2);
            }

        },
        // 获取农田
        getID(id) {
            console.log(id);
            this.getfarmerfield(id)
        },
        // 保存订单接口
        saveseedlingorder(data) {
            saveSeedlingOrder(data).then(res => {
                console.log(res);
            }).catch(err => {
                console.log(err);
            })
        },
        // 获取农户信息
        getfarmer(data) {
            getFarmer(data).then(res => {
                console.log(res);
                this.options = res.data
            }).catch(err => {
                console.log(err);
            })
        },
        // 获取所有育秧品种
        getvarirty() {
            getVarirty().then(res => {
                console.log(res);
                this.tablelist = res.data
                this.getprise(0, this.tablelist[0].sproutMoney, this.tablelist[0].seedingMoney)
            }).catch(err => {
                console.log(err);
            })
        },
        // 获取农户农田
        getfarmerfield(id) {
            getFarmerField(id).then(res => {
                console.log(res);
                this.options2 = res.data
            }).catch(err => {
                console.log(err);
            })
        }
    }
};
</script>

<style scoped lang="scss">
@function companyW($num) {
    @return ($num * 100)/1920;
}

@function companyH($num) {
    @return ($num * 100)/1080;
}

* {
    font-family: Source Han Sans CN;
    font-size: companyW(20vw);
    box-sizing: border-box;
    color: #fff;
}

::v-deep .el-dialog {
    width: companyW(980vw);
    height: companyH(680vh);
    background-color: #000;
    border-radius: companyH(40vh);
    opacity: 1;
    margin: 0 auto;

    .el-input__icon {
        line-height: companyH(30vh);
    }
}

.dv-border-box-1 {
    position: relative;
    width: companyW(980vw);
    height: companyH(680vh);
}

::v-deep .el-tabs--left .el-tabs__item.is-left {
    text-align: left;
}

::v-deep .el-dialog__body {
    padding: 0;
}

::v-deep .el-dialog__header {
    padding: 0;
}

::v-deep .el-select-dropdown {
    background-color: black;
    border: 1px solid #99C327;
    color: #99C327;
}

::v-deep .el-input--suffix .el-input__inner {
    width: companyW(250vw);
    height: companyH(40vh);
    border: 1px solid #99C327;
    font-size: companyW(18vw);
    background-color: transparent;
}

::v-deep .el-input__inner {
    background-color: #000;
    color: #fff;

}

::v-deep .el-date-el-date-editor .el-range-editor .el-input__inner .el-date-editor--daterange {
    width: companyW(250vw);
    height: companyH(30vh);
}

::v-deep .el-date-editor .el-range-input {
    background-color: transparent;
    font-size: companyW(18vw);
}

::v-deep .el-checkbox {
    width: companyW(80vw);

}

::v-deep .el-checkbox__inner {
    border-radius: 50%;
    width: companyH(20vh);
    height: companyH(20vh);
    background: rgba(2, 244, 242, 0.1);
    border: companyH(2vh) solid #99C327;


}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #99C327;
    border: #99C327;
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #fff;
}

::v-deep .el-checkbox__inner::after {
    background: #99C327;
}

::v-deep .el-radio {
    width: companyW(100vw);
    font-size: companyH(30vh);
}

::v-deep .el-radio__label {
    font-size: companyH(20vh);
}

::v-deep .el-radio__inner {

    border-radius: 50%;
    width: companyH(16vh);
    height: companyH(16vh);
    background: rgba(2, 244, 242, 0.1);
    border: companyH(2vh) solid #99C327;

}

::v-deep .el-radio__input.is-checked .el-radio__inner {
    background-color: #99C327;
    border: #99C327;
}

::v-deep .el-radio__input.is-checked+.el-radio__label {
    color: #fff;
}

.toolBox {
    height: companyH(680vh);

    padding-left: companyW(80vw);
    padding-right: companyW(80vw);
    position: relative;

    .toptitle {
        width: 100%;

        text-align: center;
        font-size: companyW(30vw);
        padding-top: companyH(30vh);
        margin-bottom: companyH(50vh);
    }

    .del {
        position: absolute;
        top: companyH(30vh);
        right: companyH(30vh);
        width: companyW(40vw);
        height: companyH(40vh);
        text-align: center;
        line-height: companyH(35vh);
        border-radius: 50%;
        font-size: companyH(30vh);
        color: #000;
        font-weight: 500;
        background-color: #fff;

    }

    .formItem {
        width: companyW(830vw);
        height: companyH(400vh);
        margin-bottom: companyH(40vh);

        .formBox {
            width: companyW(830vw);
            height: companyH(80vh);
            border-bottom: #013131 1px solid;
            display: flex;
            justify-content: left;

            .formTxtBox {
                height: 100%;
                width: companyW(100vw);
                border-right: #013131 1px solid;
                line-height: companyH(70vh);
                font-size: companyW(20vw);
                text-align: center;
            }

            .iput {
                margin: companyH(20vh) companyW(20vw);

                .ipt {
                    width: companyW(97vw);
                    height: companyH(40vh);
                    text-align: center;
                    line-height: companyH(40vh);
                    background: #001210;
                    border: 1px solid #99C327;
                    // opacity: 0.8;
                    color: #fff;
                    font-size: companyW(18vw);
                }

                span {
                    font-size: companyH(20vh);
                    height: companyH(40vh);
                    margin-left: companyH(10vh);
                    line-height: companyH(40vh);
                }
            }

            .checkBox {
                margin-left: companyH(20vh);
                width: companyW(500vw);
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                // align-self: center;
                align-items: center;
            }

            .selectBox {
                margin: companyH(20vh) companyW(10vw);
            }

            .buttonBox {
                margin-left: companyW(20vw);
                line-height: companyH(70vh);

                button {
                    width: companyW(150vw);
                    height: companyH(40vh);
                    font-size: companyW(18vw);
                    line-height: companyH(40vh);
                    background-color: #1f2227;
                    color: #99C327;
                    font-weight: 700;
                    border: 1px solid #99C327;
                }
            }

            .dateBox {
                line-height: companyH(70vh);
                margin-left: companyW(20vw);
                color: #fff;

                .el-input__inner {
                    border: #99C327 1px solid;

                }

                ::v-deep .el-range-input {
                    color: #fff;
                    font-size: companyH(18vh);
                }

                ::v-deep .el-range-separator {
                    color: #fff;
                    font-size: companyH(18vh);
                }
            }

            .inputBox {
                box-sizing: border-box;
                width: companyW(80vw);
                height: companyH(30vh);
            }

            .peisong {
                width: companyW(300vw);
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-left: companyH(20vh);

                .PS {
                    font-size: companyW(22vw);
                    width: companyW(100vw);
                    height: companyH(50vh);
                    text-align: center;
                    line-height: companyH(30vh);
                    background-color: rgba(2, 244, 242, 0);
                    border: none;
                    border-left: companyH(1vh) solid #013131;
                    border-right: companyH(1vh) solid #013131;
                    margin-right: companyW(10vw);
                }
            }
        }
    }

    .submit {
        margin-top: companyH(95vh);
        text-align: center;

        .submitBox {
            width: companyW(200vw);
            height: companyH(40vh);
            font-size: companyW(18vw);
            border: 1px solid #99C327;
            color: #99C327;
            background-color: transparent;
        }
    }
}
</style>
